import {
  UPDATE_PASSWORD,
  PASSWORD_ERROR,
  FETCH_PRACTICE_PREFERENCES_REQUEST,
  FETCH_PRACTICE_PREFERENCES_SUCCESS,
  FETCH_PRACTICE_PREFERENCES_FAILURE,
  FETCH_ADMIN_PRACTICE_STATUSES_REQUEST,
  FETCH_ADMIN_PRACTICE_STATUSES_SUCCESS,
  FETCH_ADMIN_PRACTICE_STATUSES_FAILURE,
} from '../actions/types';

export default function(
  state = {
    practicePreferences: null,
    practicePreferencesFetching: false,
    practiceStatus: null,
    loading: false,
    error: null,
  },
  action,
) {
  switch (action.type) {
    case UPDATE_PASSWORD:
      return { ...state, passwordError: null };
    case PASSWORD_ERROR:
      return { ...state, passwordError: action.payload.data.error };
    case FETCH_PRACTICE_PREFERENCES_REQUEST:
      return {
        ...state,
        practicePreferences: null,
        practicePreferencesFetching: true,
      };
    case FETCH_PRACTICE_PREFERENCES_SUCCESS:
      return {
        ...state,
        practicePreferences: action.payload,
        practicePreferencesFetching: false,
      };
    case FETCH_PRACTICE_PREFERENCES_FAILURE:
      return {
        ...state,
        practicePreferences: null,
        practicePreferencesFetching: false,
      };
    case FETCH_ADMIN_PRACTICE_STATUSES_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_ADMIN_PRACTICE_STATUSES_SUCCESS:
      return { ...state, loading: false, practiceStatus: action.payload };
    case FETCH_ADMIN_PRACTICE_STATUSES_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
}
